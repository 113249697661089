import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { colors } from "../Utilities"

const LogoSectionStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }

  .community {
    width: 100%;
    padding: 0 2rem;

    @media (min-width: 768px) {
      width: 37.5%;
    }

    p {
      text-align: center;
      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }

  .localBeer {
    width: 100%;
    padding: 0 2rem;
    @media (min-width: 768px) {
      width: 37.5%;
    }

    p {
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
    }
  }

  p {
    margin: 0;
    color: ${colors.white};
    font-size: 3rem;

    @media (min-width: 768px) {
      font-size: 2.6rem;
    }
  }

  .logoWrap {
    width: 100%;
    max-width: 25vh;
    margin: auto;

    @media (min-width: 768px) {
      width: 10vw;
      max-width: 100%;
    }

    img {
      width: 100%;
    }
  }
`

const LogoSection = ({ options }) => {
  return (
    <LogoSectionStyled>
      <div className="community">
        <p>Community focused.</p>
      </div>
      <div className="logoWrap">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://948-brewing-company.square.site/"
        >
          <Img
            fluid={
              options.options.swb_theme_main_logo.localFile.childImageSharp
                .fluid
            }
            alt="948 Menuboard"
          />
        </a>
      </div>
      <div className="localBeer">
        <p>Local beer.</p>
      </div>
    </LogoSectionStyled>
  )
}

export default LogoSection

import React from "react"
import styled from "styled-components"

import { fonts, colors } from "../Utilities"

const ShareEnjoyStyled = styled.div`
  width: 100%;
  padding-bottom: 5rem;
  text-align: center;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    width: auto;
    padding-bottom: 0;
  }

  p {
    margin: 0;
    transform: rotate(-10deg);
    color: ${colors.colorPrimary};
    font-family: ${fonts.fontSecondary};
    font-size: 2rem;
    @media (min-width: 768px) {
      font-size: 1.5vw;
    }
  }

  p:first-of-type {
    padding-right: 10rem;

    @media (min-width: 768px) {
      padding-right: 0;
    }
  }

  p:last-of-type {
    padding-left: 10rem;

    @media (min-width: 768px) {
      padding-left: 3rem;
    }
  }
`

const ShareEnjoy = () => {
  return (
    <ShareEnjoyStyled>
      <p>Enjoy + share!</p>
      <p>#948Brew</p>
    </ShareEnjoyStyled>
  )
}

export default ShareEnjoy

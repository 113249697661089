import React from "react"
import styled from "styled-components"

import SingleBeer from "./SingleBeer"

const OnTap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;

  .onTapContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 2rem 0.5vw;

    @media (min-width: 768px) {
      width: 100%;
      max-width: 140rem;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
`

const BeersOnTap = ({ onTap }) => {
  return (
    <OnTap>
      <div className="onTapContainer">
        {onTap.map((beer, index) => {
          return <SingleBeer key={index} beer={beer} />
        })}
      </div>
    </OnTap>
  )
}

export default BeersOnTap

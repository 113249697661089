import React from "react"
import styled from "styled-components"
import Ticker from "react-ticker"
import { fonts, colors } from "../Utilities"

const PricingStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 768px) {
    width: 75vw;
    max-width: 125rem;
    margin: 0 auto 5rem;
  }

  .ticker {
    width: 100%;
    padding: 0 5rem;

    &__element {
      width: 100%;
    }
  }

  .tickerDiv {
    width: 100%;
    margin: 0 auto;

    p {
      width: 100%;
      margin: 0;

      color: ${colors.white};
      font-family: ${fonts.fontPrimary};
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: center;
    }

    li {
      position: relative;
      width: 100%;
      padding: 1rem 3rem;
      color: ${colors.white};
      font-family: ${fonts.fontPrimary};
      font-size: 3rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;

      @media (min-width: 768px) {
        width: auto;
        font-size: 1.8vw;
      }

      @media (min-width: 1025px) {
      }

      &::after {
        display: block;
        position: absolute;
        top: calc(50% - 0.25vw);
        right: -0.25vw;
        width: 0.5vw;
        height: 0.5vw;
        border-radius: 50%;
        background: ${colors.white};
        content: "";
      }
    }

    li:last-of-type {
      &::after {
        display: none;
      }
    }

    li:nth-of-type(3n + 3) {
      &::after {
        display: none;
      }
    }
  }
`

const Pricing = ({ data, tickerData }) => {
  console.log(tickerData)
  return (
    <PricingStyled>
      <Ticker>
        {() => (
          <div className="tickerDiv">
            <p>{tickerData}</p>
          </div>
        )}
      </Ticker>
      {/* <ul>
        <li>Flight of 4 {data.flight}</li>
        <li>5oz Sample {data.sample}</li>
        <li>16oz glass {data.pints}</li>
        <li>32oz Can {data.cans}</li>
        <li>64oz Growler Fill {data.growler}</li>
      </ul> */}
    </PricingStyled>
  )
}

export default Pricing

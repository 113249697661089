import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { fonts, colors } from "../Utilities"

const SingleBeerStyled = styled.div`
  position: relative;
  align-self: flex-start;
  width: calc(100% / 2);
  padding: 4rem 0.5vw;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(100% / 6);
    padding: 0 1vw;
  }

  .logo {
    padding: 1rem 2.25vw;
  }

  .percentage {
    margin-top: 1vw;
  }

  p {
    margin: 0;
    color: ${colors.white};
    font-family: ${fonts.fontPrimary};
    font-size: 2rem;

    @media (min-width: 768px) {
      font-size: 1.2vw;
    }
  }
  .percentage {
    .beerName {
      @media (min-width: 768px) {
        font-size: 1.4rem;
      }
    }
  }

  p:first-of-type {
    position: relative;
    padding-bottom: 1rem;
    &::after {
      position: absolute;
      display: block;
      bottom: 0;
      left: 1rem;
      right: 1rem;
      height: 0.25rem;
      background: ${colors.black};
      content: "";
    }
  }

  p:last-of-type {
    padding-top: 1rem;
    @media (min-width: 768px) {
      font-size: 1.2vw;
    }
  }
`

const SingleBeer = ({ beer }) => {
  console.log("BEER: ", beer)
  return (
    <SingleBeerStyled>
      <div className="logo">
        {beer.isgif ? (
          <>
            <img src={beer.logo.localFile.publicURL} alt={beer.name} />
          </>
        ) : beer.logo.localFile &&
          beer.logo.localFile.childImageSharp &&
          beer.logo.localFile.childImageSharp.fluid ? (
          <Img
            fluid={beer.logo.localFile.childImageSharp.fluid}
            alt={beer.name}
          />
        ) : (
          <>
            <img src="gif.gif" alt={beer.logo.localFile.publicURL} />
          </>
        )}
      </div>
      <div className="percentage">
        <p className="beerName">{beer.name}</p>
        <p>{beer.percentage}</p>
      </div>
    </SingleBeerStyled>
  )
}

export default SingleBeer

import React from "react"
import styled from "styled-components"
import SocialFollow from "./allSite/SocialMedia/SocialFollow"

const SocialIconsWrapper = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .shopBeer {
    width: 100%;
    text-align: center;
  }

  .following {
    margin: 0;
    color: #fff;
    text-align: center;
  }
`

const ScoialIconsWrap = () => {
  return (
    <SocialIconsWrapper>
      <div className="shopBeer">
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://948-brewing-company.square.site/"
          >
            Shop Beer
          </a>
        </p>
      </div>
      <SocialFollow
        icons={[
          {
            social_type: "instagram",
            social_url: "https://www.instagram.com/948brewing/?hl=en",
          },
          {
            social_type: "facebook",
            social_url: "https://www.facebook.com/948Brewing/",
          },
          {
            social_type: "twitter",
            social_url: "https://twitter.com/948brewing?lang=en",
          },
        ]}
      />
      <p className="following">@948brewing</p>
    </SocialIconsWrapper>
  )
}

export default ScoialIconsWrap

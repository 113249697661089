import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const WoodBackground = styled(BgImg)`
  display: flex;
  position: absolute !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 15rem 0;
  background-position: bottom center;
  background-repeat: repeat-y !important;
  background-size: 100% auto !important;

  @media (min-width: 768px) {
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
`

const getData = graphql`
  {
    woodBg: file(relativePath: { eq: "woobg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Woodbg = props => {
  const data = useStaticQuery(getData)
  const imageData = data.woodBg.childImageSharp.fluid
  return (
    <WoodBackground Tag="div" fluid={imageData}>
      {props.children}
    </WoodBackground>
  )
}

export default Woodbg

import React from "react"
import styled, { keyframes } from "styled-components"

import RedReciver from "../elements/RedReciver"

const swing = keyframes`
  0%,
  50%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
`

const PhoneContainer = styled.div`
  position: absolute;
  top: 0;
  right: 7.5vw;
  width: 5rem;
  height: 20rem;
  transform-origin: center top;
  animation-name: ${swing};
  animation-duration: 3s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  z-index: 10;

  img {
    width: 100%;
  }
`

const Reciver = () => {
  return (
    <PhoneContainer>
      <RedReciver />
    </PhoneContainer>
  )
}

export default Reciver

import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Background from "../components/Background"
import LogoSection from "../components/LogoSection"
import Reciver from "../components/Reciver"
import Pricing from "../components/Pricing"
import BeersOnTap from "../components/BeersOnTap"
import ShareEnjoy from "../components/ShareEnjoy"
import ScoialIconsWrap from "../components/ScoialIconsWrap"

const MenuBoard = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100vh;
  }
`

const IndexPage = props => {
  const { options } = props.data

  const pricingData = {}
  pricingData.flight = props.data.onTap.acf._brew_pricing_flight
  pricingData.sample = props.data.onTap.acf._brew_pricing_4oz
  pricingData.pints = props.data.onTap.acf._brew_pricing_pints
  pricingData.cans = props.data.onTap.acf._brew_pricing_32oz
  pricingData.growler = props.data.onTap.acf._brew_pricing_growler_fill

  const tickerData = props.data.onTap.acf._brew_ticker_content

  const onTap = props.data.onTap.acf._brew_on_tab

  const seoInfo = props.data.seoInfo

  return (
    <Layout>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <MenuBoard>
        <LogoSection options={options} />
        <BeersOnTap onTap={onTap} />
        <Pricing data={pricingData} tickerData={tickerData} />
        <ScoialIconsWrap />
        <ShareEnjoy />
        <Reciver />
        <Background />
      </MenuBoard>
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    seoInfo: wordpressPage(slug: { eq: "menu-board" }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    onTap: wordpressPage(slug: { eq: "menu-board" }) {
      acf {
        _brew_on_tab {
          name
          percentage
          ibu
          isgif
          logo {
            alt_text
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        _brew_pricing_pints
        _brew_pricing_growler_fill
        _brew_pricing_flight
        _brew_pricing_4oz
        _brew_pricing_32oz
        _brew_ticker_content
      }
    }

    options: wordpressAcfOptions {
      options {
        swb_theme_main_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

import React from "react"
import styled from "styled-components"

import Woodbg from "../elements/Woodbg"

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const Background = () => {
  return (
    <BackgroundContainer>
      <Woodbg />
    </BackgroundContainer>
  )
}

export default Background
